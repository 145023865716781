<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-10-08 01:02 -->
<!-- Description:  -->
<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <input
          class="form-control"
          type="date"
          v-model="date"
          @change="reload()"
        />
      </div>
      <div class="col">
        {{ dateVerbose }}
      </div>
      <div class="col-lg-3 text-right">
        <button class="btn btn-primary" @click="reload()">Recargar</button>
      </div>
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="sticky-top">
          <th class="text-center bg-light">Hora</th>
          <th
            v-for="(doc, it1) in doctors"
            :key="it1"
            class="text-center bg-light"
          >
            {{ doc.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="h in hours" :key="h">
          <th class="text-center">{{ h }}:00</th>
          <td v-for="(doc, it2) in doctors" :key="it2" class="p-0">
            <!-- <span>{{ h }} - {{ doc.id }}</span> -->
            <div
              class="w-100 p-2 border border-dark"
              v-for="q in list.filter(
                (x) => x.employee_id == doc.id && x.hour === h
              )"
              :key="q.id"
              v-quote-bg-color="q.status_id"
            >
              {{ q.client_name }}
              <br>
              <small><b>{{ q.status_label }}</b></small>
              <br />
              <span class="badge badge-light">{{ q.time_label }}</span>
              <br />
              {{ q.commentary }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center">
      <small>Ultima actualizacion {{ lastUpdate }}</small>
      <br>
      <small>Esta pagina se recarga automaticamente cada 5 minutos.</small>
    </div>
  </div>
</template>
<script>
import myMoment from "../../lt/utils/myMoment";
import { DentalClinicService } from "../service";
import _ from "lodash";
import { quoteBgColor } from "../quotes/quote-bg-color-directive.js";
// script content
const defaultHours = Array.from(Array(24)).map((x, i) => i);

export default {
  name: "QuoteFullScreen",
  components: {
    //
  },

  directives: {
    quoteBgColor,
  },

  props: {
    //
  },

  data: () => ({
    date: null,
    list: [],
    doctors: [],
    first_hour: null,
    hours: [],
    dateVerbose: null,
    autoReloadFunction: null,
    lastUpdate: null,
  }),

  computed: {
    //
  },

  // watch: {},

  created() {
    //
  },

  mounted() {
    this.date = myMoment().getCurrentDate();
    this.reload();
    this.autoReloadFunction = () => {
      this.reload();
    };
    setInterval(this.autoReloadFunction, 300000);
  },

  methods: {
    async reload() {
      this.dateVerbose = myMoment(this.date)
        .calendar()
        .replace(" a las 0:00", "");
      let statusList = this.$store.state.config.service.quotes.status_list;
      // console.log(statusList);
      let res = await DentalClinicService.getQuotes({
        date: this.date,
        per_page: 1000,
      });
      this.list = res.data
        .filter((x) => x.status_id != 10)
        .map((x) => {
          x.time_label =
            myMoment(x.ini_date).format("hh:mma") +
            " - " +
            myMoment(x.end_date).format("hh:mma");
          x.hour = myMoment(x.ini_date).hour();
          x.status_label = statusList[x.status_id]
            ? statusList[x.status_id].name
            : "";
          return x;
        });
      let hourFirstQuote = this.list.reduce(
        (p, c) => (p > c.hour ? c.hour : p),
        24
      );
      let hours = JSON.parse(JSON.stringify(defaultHours));
      this.hours = hours.filter((x) => x >= hourFirstQuote);
      let doctors = _.uniqWith(
        res.data.map((x) => {
          return {
            name: x.employee_name,
            id: x.employee_id,
          };
        }),
        _.isEqual
      );
      this.doctors = doctors;
      this.lastUpdate = myMoment().format();
      // console.log(doctors);
    },
    //
    //
  },
};
</script>
<style scoped></style>
