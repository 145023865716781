import { render, staticRenderFns } from "./QuoteFullScreen.vue?vue&type=template&id=47cb84ba&scoped=true&"
import script from "./QuoteFullScreen.vue?vue&type=script&lang=js&"
export * from "./QuoteFullScreen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47cb84ba",
  null
  
)

export default component.exports